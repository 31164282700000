import { Account } from "@radixdlt/radix-dapp-toolkit";
import { NumericFormat } from "react-number-format";
import { useActionData, useSubmit, useNavigation, useFetcher, Form, Link } from "@remix-run/react";
import { useRef, useState, useEffect } from "react";
import { usePrevious } from "~/hooks/usePrevious";
import { classNames, getDecimalSeperator, getThousandSeperator, roundToDecimals } from "~/lib/util";
import { useRadix } from "~/rdt/radixProvider";
import AccountSelector from "./account-selector";
import InputButtons from "./input-buttons";
import RoutesTable from "./swap/routes-table";
import TokenPrice from "./token-price";
import TokenSelector from "./token-selector";
import TransactionButton from "./transaction-button";
import { loader as walletLoader } from "~/routes/wallet.$account";
import { actionSwap, loadSwap } from "~/lib/swap";
import { PriceImpact } from "./price-impact";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

const doneTypingInterval = 500;
const updateDataInterval = 10000;

let timeOutId: any = undefined;

export function TokenSwap({ tokens, routes, manifest, baseToken, quoteToken, priceImpact, fee, error, displayRouting, sendIt }: typeof loadSwap) {
	const actionData = useActionData<typeof actionSwap>();

	const submit = useSubmit();
	const navigation = useNavigation();
	const formRef = useRef<HTMLFormElement>(null);
	const walletFetcher = useFetcher<typeof walletLoader>();

	const { sendTransaction } = useRadix();

	const [account, setAccount] = useState<string | null>(null);
	const [inputToken, setInputToken] = useState(tokens[baseToken.index]);
	const [outputToken, setOutputToken] = useState(tokens[quoteToken.index]);
	const [inputAmount, setInputAmount] = useState(baseToken.amount);
	const [userError, setUserError] = useState<string | null>(null);

	const previousBaseToken = usePrevious(tokens[baseToken.index]);

	function onAccount(account: Account) {
		setAccount(account.address);

		walletFetcher.load(`/wallet/${account.address}`);
	}

	function onRefresh() {
		walletFetcher.load(`/wallet/${account}`);
		fetchQuote(formRef.current);
	}

	useEffect(() => {
		if (!account) {
			return;
		}

		if (sendIt) {
			console.log("send it!");
			submitTransaction(null);
		}
	}, [account]);

	useEffect(() => {
		// Only run on change not on set
		if (!previousBaseToken) {
			return;
		}

		setInputToken(tokens[baseToken.index]);
		setOutputToken(tokens[quoteToken.index]);
		//  setInputAmount(baseToken.amount);
	}, [baseToken.index, quoteToken.index]);

	useEffect(() => {
		// Only run on change not on set
		if (!previousBaseToken) {
			return;
		}

		fetchQuote(formRef.current);
	}, [inputToken, outputToken]);

	useEffect(() => {
		// Only run on change not on set
		// if (!previousBaseToken) {
		// 	return;
		// }

		fetchQuote(formRef.current);
	}, [inputAmount]);

	function debounceSetInput(value: string) {
		clearTimeout(timeOutId);

		timeOutId = setTimeout(() => {
			setInputAmount(value);
		}, doneTypingInterval);
	}

	function fetchQuote($form) {
		const formData = new FormData($form);
		formData.set("inputAmount", inputAmount);

		// checkBalance();

		submit(formData, {
			method: $form.getAttribute("method") ?? $form.method,
			action: $form.getAttribute("action") ?? $form.action,
		});
	}

	async function submitTransaction(event) {
		if (!account) {
			return;
		}

		event?.preventDefault();

		checkBalance();

		const transactionManifest = actionData ? actionData.manifest : manifest;
		const result = await sendTransaction(transactionManifest.replaceAll("<WALLET_ACCOUNT_ADDRESS>", account), `Your ${inputToken.symbol}/${outputToken.symbol} swap is optimized by Astrolescent.`);

		onRefresh();
	}

	function checkBalance() {
		const balance = walletFetcher.data?.filter((b) => b.address == inputToken.address)[0]?.amount;

		if (balance && +balance < +inputAmount) {
			console.log("Not enough balance)");
			setUserError(`Insufficient ${inputToken.symbol} in your account`);
		} else {
			setUserError(null);
		}
	}

	function switchTokens(event) {
		event.preventDefault();

		setInputToken(outputToken);
		setOutputToken(inputToken);
	}

	return (
		<>
			<Form method="post" ref={formRef} onSubmit={submitTransaction} className="space-y-6">
				{displayRouting ? (
					<span className="-mb-4 hidden justify-end space-x-2 px-4 sm:flex">
						<Link to={`/token/${outputToken.address}?from=${inputToken.address}&amount=${inputAmount}`}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 text-white hover:text-astro">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
								/>
							</svg>
						</Link>

						<svg
							onClick={onRefresh}
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="h-4 w-4 cursor-pointer rounded-full border border-transparent text-white transition-transform duration-500 ease-out hover:rotate-180 hover:text-astro"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
							/>
						</svg>
					</span>
				) : null}

				<section className="overflow-hidden rounded-lg shadow-lg">
					<AccountSelector mode="light" onAccount={onAccount}></AccountSelector>
					<div className="bg-white px-4 py-6">
						<div className="flex items-center justify-between">
							<label htmlFor="tokenInput" className="font-optical-24-550 tracking-astro text-sm">
								From
							</label>
							{walletFetcher.data ? (
								<span className="justify-right flex flex-row items-center space-x-2 truncate text-right">
									<span onClick={() => setInputAmount(walletFetcher.data?.filter((b) => b.address == inputToken.address)[0]?.amount)} className="cursor-pointer font-mono text-sm">
										{roundToDecimals(walletFetcher.data.filter((b) => b.address == inputToken.address)[0]?.amount || 0)} {inputToken.symbol}
									</span>
									{/* <InputButtons
                                 token={inputToken}
                                 amount={inputAmount}
                                 balance={walletFetcher.data.filter((b) => b.address == inputToken.address)[0]?.amount}
                                 onClick={(value) => setInputAmount(value)}
                              /> */}
								</span>
							) : null}
						</div>
						<div className="mb-3 mt-2 flex items-center">
							<NumericFormat
								id="inputAmount"
								name="inputAmount"
								value={inputAmount}
								onValueChange={(values, sourceInfo) => {
									debounceSetInput(values.value);
								}}
								allowLeadingZeros
								thousandSeparator={getThousandSeperator()}
								decimalSeparator={getDecimalSeperator()}
								className="mask-right font-optical-24 w-40 flex-auto border-none bg-transparent p-0 text-4xl lining-nums placeholder-neutral-400 focus:border-transparent focus:outline-none focus:ring-astro"
							/>

							<TokenSelector name="inputToken" tokens={tokens} balances={walletFetcher.data} selected={inputToken} onSelected={setInputToken} />
						</div>
						<span className="font-optical-24-550 tracking-astro font-mono text-sm lining-nums text-black">
							${(Math.round(+inputAmount * inputToken.tokenPriceUSD * 100) / 100).toLocaleString()}
						</span>
					</div>
					{(error && !actionData) || actionData?.error || userError ? (
						<p className="text-md bg-red-800 px-4 py-2 pb-4 text-center font-medium text-white">{actionData?.error || error || userError}</p>
					) : null}

					<div className="z-0 flex h-6 justify-center overflow-visible bg-black/30 py-2">
						<div onClick={switchTokens} className="-mt-5 flex h-12 w-12 cursor-pointer items-center rounded-lg border-2 border-neutral-300 bg-white text-black">
							<ChevronDownIcon className="mx-auto h-6 w-6 transition-transform duration-500 ease-out hover:rotate-180" aria-hidden="true" />
						</div>
					</div>

					<div className="rounded-b-lg bg-white px-4 py-6">
						<div className="flex items-center justify-between">
							<label htmlFor="tokenOutput" className="font-optical-24-550 tracking-astro text-sm">
								To
							</label>
							<TokenPrice
								priceData={{
									inputToken,
									outputToken,
									inputAmount: actionData ? actionData.baseToken.amount : baseToken.amount,
									outputAmount: actionData ? actionData.quoteToken.amount : quoteToken.amount,
								}}
								onClick={() => {}}
							/>
						</div>
						<div className="mb-3 mt-2 flex items-center">
							<NumericFormat
								id="inputAmount"
								name="inputAmount"
								displayType="text"
								value={(actionData ? actionData.quoteToken.amount : quoteToken.amount).toFixed(6)}
								allowLeadingZeros
								thousandSeparator={getThousandSeperator()}
								decimalSeparator={getDecimalSeperator()}
								className={classNames(
									navigation.state == "submitting" ? "animate-pulse rounded bg-neutral-200 text-black/60" : "bg-transparent",
									"mask-right font-optical-24 w-40 flex-auto border-none bg-transparent p-0 text-4xl lining-nums placeholder-neutral-400 transition-colors focus:border-transparent focus:outline-none focus:ring-astro",
								)}
							/>
							<TokenSelector name="outputToken" tokens={tokens} balances={walletFetcher.data} selected={outputToken} onSelected={setOutputToken} />
						</div>
						<span className="font-optical-24-550 tracking-astro font-mono text-sm lining-nums text-black">
							${(Math.round(+(actionData ? actionData.quoteToken.amount : quoteToken.amount) * outputToken.tokenPriceUSD * 100) / 100).toLocaleString()}
							{navigation.state == "submitting" ? null : (
								<span className="ml-3">
									<PriceImpact
										input={(actionData ? actionData.baseToken.amount : baseToken.amount) * inputToken.tokenPriceXRD}
										output={(actionData ? actionData.quoteToken.amount : quoteToken.amount) * outputToken.tokenPriceXRD}
									/>
								</span>
							)}
						</span>

						<div className="mt-6">
							<TransactionButton title="Swap" onClick={submitTransaction} />
						</div>
					</div>
				</section>

				{displayRouting ? (
					actionData ? (
						<RoutesTable
							tokens={tokens}
							routes={actionData.routes}
							inputAmount={actionData.baseToken.amount}
							outputAmount={actionData.quoteToken.amount}
							inputToken={inputToken}
							outputToken={outputToken}
							priceImpact={actionData.priceImpact}
							fee={actionData.fee}
						/>
					) : (
						<RoutesTable
							tokens={tokens}
							routes={routes}
							inputAmount={baseToken.amount}
							outputAmount={quoteToken.amount}
							inputToken={inputToken}
							outputToken={outputToken}
							priceImpact={priceImpact}
							fee={fee}
						/>
					)
				) : null}

				{/* <div className="w-full flex justify-center items-center py-4 px-4 rounded-md shadow-md text-lg font-medium text-white bg-gradient-to-r from-cyan-900 to-cyan-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-astro border-cyan-600 border">
               <>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                     />
                  </svg>
                  Insufficient {inputToken.symbol} balance
               </>
            </div> */}
				<input type="hidden" name="fromAddress" defaultValue={account || ""} />
			</Form>
		</>
	);
}
